//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'
import Img from 'gatsby-image'

//Icons
import { MdOpenInNew } from 'react-icons/md'

const Snippet = ({ title, id }) => (
  <div class="d-flex flex-row align-items-center">
    <a target="_blank" href={`https://storylava.com/template/${id}`}>
      {title}
    </a>
    <MdOpenInNew class="blue-1 ml-1" size={18} />
  </div>
)
export default Snippet
