import PostGallery from "../../../../src/components/marketing/postGallery";
import PostCta from "../../../../src/components/marketing/postCta";
import PostTemplateCta from "../../../../src/components/marketing/postTemplateCta";
import PostTemplateLink from "../../../../src/components/marketing/postTemplateLink";
import * as React from 'react';
export default {
  PostGallery,
  PostCta,
  PostTemplateCta,
  PostTemplateLink,
  React
};