//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'
import Img from 'gatsby-image'

//Icons
import { FaArrowRight } from 'react-icons/fa'
import { MdOpenInNew } from 'react-icons/md'

const Snippet = ({ images, containerClass }) => (
  <div
    class={`image-gallery-container my-3 d-flex flex-row flex-wrap align-items-stretch justify-content-center ${
      containerClass ? containerClass : ''
    }`}
  >
    {images.map((image) => {
      if (image.id) {
        return (
          <div class="d-flex">
            <a
              class="d-flex flex-column img-thumbnail-container"
              href={`https://storylava.com/template/${image.id}`}
              target="_blank"
              noopener
              noreferrer
            >
              <img
                src={`https://storylavamedia-prod.s3.amazonaws.com/template/${image.id}/${image.imageid}.png`}
                alt={image.alt ? image.alt : ''}
                class=" m-1"
              />
              <div class="d-flex flex-row align-items-start justify-content-start">
                <div class="image-gallery-title small gray-1 m-1 line-height-1">
                  {image.alt}
                </div>
                <div class="image-gallery-link ml-auto">
                  <MdOpenInNew class="gray-1" size={18} />
                </div>
              </div>
            </a>
          </div>
        )
      } else {
        return (
          <div class="d-flex flex-column img-thumbnail-container">
            <img
              src={`https://storylavamedia-prod.s3.amazonaws.com/template/${image.id}/${image.imageid}.png`}
              alt={image.alt ? image.alt : ''}
              class=" m-1"
            />
            <div class="d-flex flex-row align-items-start justify-content-start">
              <div class="image-gallery-title small gray-1 m-1 line-height-1">
                {image.alt}
              </div>
              <div class="image-gallery-link ml-auto">
                <MdOpenInNew class="gray-1" size={18} />
              </div>
            </div>
          </div>
        )
      }
    })}
  </div>
)

export default Snippet
