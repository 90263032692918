//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'
import Img from 'gatsby-image'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = ({ title, tag }) => (
  <div class="post-template-link my-3 px-3 py-4 rounded-corners gradient-callout-algae d-flex flex-column">
    <h4 class="white-1 font-weight-light text-center">See {title} templates</h4>
    <div class="d-flex flex-row align-items-center justify-content-center">
      <a
        class="btn btn-cta-outline mt-3"
        href={`https://storylava.com/tag/${tag}`}
        target="_blank"
      >
        View {title} templates <FaArrowRight />
      </a>
    </div>
  </div>
)
export default Snippet
